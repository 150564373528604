.divider {
  border-color: #47385f;
  max-width: 150px;
  height: 1.5px;
  border-left: 0px;
  border-right: 0px;
}

.paragraph{
  text-align: center!important;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.header{
  font-size: 45px;
  text-align: center!important;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}

.inputStyles{ 
  border-left-color: white !important;
  border-right-color: white !important;
  border-bottom: none !important;
  font-size: 1.5em !important;
  padding-left: 0 !important;
  padding-bottom: 1.5em !important;  
  
}
.Messagebox{
  border-left-color: white !important;
  border-right-color: white !important;
  padding-left: 0 !important;
  padding-bottom: 5em !important; 
  font-size: 1.5em !important;
  
}
.ButtonStyles{
  border: 1px solid #222 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  font-weight: 1000 !important;
  color: #222 !important;
  text-transform: uppercase !important;
  transition: .10s !important;
}