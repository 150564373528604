.header{
  color: black;
  text-align: center!important;
  font-size: 3em;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: .5rem;
  line-height: 1.1;
  text-transform: uppercase;

}

.products{
  color: black;
  margin-bottom: 0!important;
  text-align: center!important;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;   
  font-size: 1.75rem;
  
  line-height: 1.1;
}


.examples{
  margin-bottom: 0!important;
  margin-top: 0;
  font-size: 18px;
  text-align: center!important;
}



.divider{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-color: #47385f;
  max-width: 150px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;

}
.paragraph{
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0!important;
  margin-top: 12px;
} 

.rowstyling{
  text-align: center!important;
}

.wrapper {
  padding: 8em 0em !important;
}

.column{
  align-self: top !important;
}

@media only screen and (min-width: 481px) and (max-width: 767px){
  .wrapper{
    padding: 4em 6em !important;
  }
  
}


@media only screen and (max-width: 480px) {
  .wrapper{
    padding: 4em 2em !important;
  }
  .header{
    font-size: 2em;
  }
  .products{
    font-size: 1.5em !important;
  }
  .paragraph{
    margin-top: 10px;
    font-size: 1.1em !important;
  }
}