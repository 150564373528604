@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,600,500,700,800,900);
@import url(https://polyhorse.com/assets/vendor/font-awesome/css/font-awesome.min.css?v=6f113ae353");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.CaseStudy_divider__2iKJm{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-color: #47385f;
  max-width: 150px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;

}
.CaseStudy_h1Text__3S40T{
  color: black;
  text-align: center!important;
  font-size: 52px;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin-top: 0;
  padding-top: 80px;
  line-height: 1.1;
}
.CaseStudy_wrapping__2B1mD{
  padding-bottom: 80px !important;
}


h3{
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  font-size: 1.75rem !important;
  line-height: 1.1;
}
p{
  font-size: 18px !important;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 5rem;
  box-sizing: inherit;
  
}
.Footer_bgImage__3ri_1 {
	background-image: url(/static/media/footerBg.f94b2f79.jpeg) !important;
	background-attachment: fixed !important;
	background-size: cover !important;
  text-align: center !important; 
  border-top: solid 20px #222 !important;
}

.Footer_columnStyling__25JCt{
  margin: 75px 0;


}


.Footer_columnStyling__25JCt h4 {
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif !important ;
  font-weight: 1000 !important;
  text-transform: uppercase !important;
  text-align: center;
} 
.Footer_columnStyling__25JCt p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

}
.Footer_rowstyling__32NUq{ 

  text-align: center !important;
  margin-bottom: 25px;
  padding-bottom: 70px;
}
.Footer_socials__kc1yA{
color: rgba(255, 255, 255, 0.7);
text-align: center !important; 
padding-top: 50px !important;
padding-bottom: 80px !important;

} 

.Footer_copyright__2AbKt{
  color: rgba(255, 255, 255, 0.7) ;
  text-align: center !important;
  

}
@media only screen and (max-width: 480px) {
  .Footer_bgImage__3ri_1{
    background-attachment: local !important;
  
  }}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 

.CaseStudyCard_bgImage__b32ZB{
  background-image: url(/static/media/caseStudyMorphBg.3c48af36.jpeg);
	background-attachment: fixed !important;
	background-size: cover !important;

}

.CaseStudyCard_bgImageAttachment__3Osvd{
  background-image: url(/static/media/caseStudyMorphBg.3c48af36.jpeg);
  background-size: cover !important;
}



.CaseStudyCard_logoImage__3rrOe{
  max-height: 250px;
  padding: 20px 0px;
  max-width: 80%;
  height: auto;
  display: block!important;
  vertical-align: middle;
  border-style: none;
  margin-right: auto!important;
  margin-left: auto!important;
}
.CaseStudyCard_rowstyling__3yxAH {
  margin-right: 0px!important;
  margin-left: 0px!important;
  justify-content: center!important;
  min-height: 320px;
  max-width: 720px; 
  box-sizing: inherit;
}

.CaseStudyCard_buttonStyle__hc4sm{
  border: 1px solid white;
  background-color: transparent;
  font-weight: 900;
  padding: 12px 18px;
  touch-action: manipulation;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0;
  color: #fff;
  font-size: 1rem;
  line-height: 1.25;
  cursor: pointer;
  transition: .10s;
}
.CaseStudyCard_buttonStyle__hc4sm:hover{
  background-color: white;
  color: #212529;

}

.CaseStudyCard_column1__kbrQs{
  align-self: center!important;



}

.CaseStudyCard_text__1pOG9{
  color: #CCCCCC;
  font-size: 18px;
  line-height: 1.5;
  margin: 0 0 1em;

}
@media only screen and (max-width: 480px) {
  .CaseStudyCard_bgImage__b32ZB{
    background-attachment: local !important;
  
  }}
.Quote_bgImage__2uHZR {
	background-image: url(/static/media/quoteBg.b7f51e97.jpg) !important;
	background-attachment: fixed !important;
	background-size: cover !important;
}

.Quote_quoteText__9VpR0{
color: white;
font-size: 36px;
line-height: 38px;
font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 400;
margin: 570 114;
text-align: left;
}

.Quote_quoteAuthor__2CltJ{
	color: white;
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	text-align: right;
	font-size: 30px;
	line-height: 45px;

	}
	
	.Quote_columnStyling__2MTth{
		padding: 150px 200px;
		text-align: center;
		background-size: cover;
    background-attachment: scroll;
	
	
	}
	.Quote_gridStyling__333ij {
max-width: 770px !important;

	}

	@media only screen and (min-width: 481px) and (max-width: 768px) {
		.Quote_bgImage__2uHZR{

		padding: 4em 2em !important;
		}
		.Quote_quoteText__9VpR0{
			font-size: 1.8em;
			line-height: 1.5em;

		}
		.Quote_quoteAuthor__2CltJ{
			font-size: 1.5em;
			line-height: 20px;
		}
		
	}


		@media only screen and (max-width: 480px) {
			.Quote_bgImage__2uHZR{
				max-height: 400px;
			min-width: 375px;
			background-attachment: local !important;
			padding: 4em 2em !important;
			}
			.Quote_quoteText__9VpR0{
				font-size: 1.5em;
				line-height: 1.5em;

			}
			.Quote_quoteAuthor__2CltJ{
				font-size: 1.5em;
				line-height: 20px;
			}
		}
	
.Hero_bgImage__lb-Ct {
	background-image: url(/static/media/heroBG.f94b2f79.jpeg) !important;
	
	background-size: cover !important;
  background-attachment: fixed !important;
  padding: 8em 0em !important;
}
.Hero_logo__38UAQ{
  max-width: 250px ;
  max-height: 250px;
  display: block;
  margin: auto;
}
.Hero_divider__25axx {
  border-color: #e9e6ed;
  max-width: 100px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;
  border-top: 1px solid #e9e6ed;
  border-bottom: 1px solid #e9e6ed;

}
.Hero_name__3JFDE{
  color: white;
  text-align: center;
  font-size: 5rem;
  line-height: 5rem;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;


}
.Hero_slogan__Axn9T{
  color: #fff;
  text-align: center;
  font-size: 2.2rem !important;
  line-height: 3.2rem;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 370;



}
@media only screen and (min-width: 1024px) {
.Hero_bgImage__lb-Ct{
  min-height: 700px;

}

}
@media only screen and (max-width: 480px) {
  .Hero_bgImage__lb-Ct{
    max-width: 450px;
    min-height: 50px;
    background-attachment: local !important;
    padding: 4em 0em !important;
  }
  .Hero_name__3JFDE{
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0;

  }
  .Hero_logo__38UAQ{
    max-width: 150px ;
    max-height: 150px;
  }
  .Hero_slogan__Axn9T{
    font-size: 1.5rem !important;
  }
  .Hero_divider__25axx{
    display: none;
  }
  
  }
  
  




.CaseStudyHeader_header__3nz8q {
  text-align: center;
color: #212529;
font-size: 50px;
font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 900;
text-transform: uppercase;

}

.CaseStudyHeader_text__6ap80 {
  text-align: center;
color: #212529;
font-size: 18px;
line-height: 1.5;
font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 300;
visibility: visible;
margin-top: 16px;

}
.CaseStudyHeader_divider__3X-pS {
  border-color: #47385f;
  max-width: 100px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;
}
.CaseStudyHeader_wrapper__2vCDw{
padding: 100px 0px !important;

}
@media only screen and (max-width: 480px) {
 
  .CaseStudyHeader_wrapper__2vCDw{
    padding:  0px !important;
  }
}
.Services_header__1aPFz{
  color: black;
  text-align: center!important;
  font-size: 3em;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: .5rem;
  line-height: 1.1;
  text-transform: uppercase;

}

.Services_products__2Nbkz{
  color: black;
  margin-bottom: 0!important;
  text-align: center!important;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;   
  font-size: 1.75rem;
  
  line-height: 1.1;
}


.Services_examples__3kQu3{
  margin-bottom: 0!important;
  margin-top: 0;
  font-size: 18px;
  text-align: center!important;
}



.Services_divider__qUWRy{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-color: #47385f;
  max-width: 150px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;

}
.Services_paragraph__tlwOY{
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0!important;
  margin-top: 12px;
} 

.Services_rowstyling__2GAVz{
  text-align: center!important;
}

.Services_wrapper__2kRFC {
  padding: 8em 0em !important;
}

.Services_column__2HVEz{
  align-self: top !important;
}

@media only screen and (min-width: 481px) and (max-width: 767px){
  .Services_wrapper__2kRFC{
    padding: 4em 6em !important;
  }
  
}


@media only screen and (max-width: 480px) {
  .Services_wrapper__2kRFC{
    padding: 4em 2em !important;
  }
  .Services_header__1aPFz{
    font-size: 2em;
  }
  .Services_products__2Nbkz{
    font-size: 1.5em !important;
  }
  .Services_paragraph__tlwOY{
    margin-top: 10px;
    font-size: 1.1em !important;
  }
}
.ContactForm_divider__2dnqi {
  border-color: #47385f;
  max-width: 150px;
  height: 1.5px;
  border-left: 0px;
  border-right: 0px;
}

.ContactForm_paragraph__IiWSS{
  text-align: center!important;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.ContactForm_header__gfqBh{
  font-size: 45px;
  text-align: center!important;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}

.ContactForm_inputStyles__gSByl{ 
  border-left-color: white !important;
  border-right-color: white !important;
  border-bottom: none !important;
  font-size: 1.5em !important;
  padding-left: 0 !important;
  padding-bottom: 1.5em !important;  
  
}
.ContactForm_Messagebox__3WG4h{
  border-left-color: white !important;
  border-right-color: white !important;
  padding-left: 0 !important;
  padding-bottom: 5em !important; 
  font-size: 1.5em !important;
  
}
.ContactForm_ButtonStyles__3aZzU{
  border: 1px solid #222 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  font-weight: 1000 !important;
  color: #222 !important;
  text-transform: uppercase !important;
  transition: .10s !important;
}
