.bgImage {
	background-image: url('../../../assets/images/quoteBg.jpg') !important;
	background-attachment: fixed !important;
	background-size: cover !important;
}

.quoteText{
color: white;
font-size: 36px;
line-height: 38px;
font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 400;
margin: 570 114;
text-align: left;
}

.quoteAuthor{
	color: white;
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	text-align: right;
	font-size: 30px;
	line-height: 45px;

	}
	
	.columnStyling{
		padding: 150px 200px;
		text-align: center;
		background-size: cover;
    background-attachment: scroll;
	
	
	}
	.gridStyling {
max-width: 770px !important;

	}

	@media only screen and (min-width: 481px) and (max-width: 768px) {
		.bgImage{

		padding: 4em 2em !important;
		}
		.quoteText{
			font-size: 1.8em;
			line-height: 1.5em;

		}
		.quoteAuthor{
			font-size: 1.5em;
			line-height: 20px;
		}
		
	}


		@media only screen and (max-width: 480px) {
			.bgImage{
				max-height: 400px;
			min-width: 375px;
			background-attachment: local !important;
			padding: 4em 2em !important;
			}
			.quoteText{
				font-size: 1.5em;
				line-height: 1.5em;

			}
			.quoteAuthor{
				font-size: 1.5em;
				line-height: 20px;
			}
		}
	