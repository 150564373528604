



.header {
  text-align: center;
color: #212529;
font-size: 50px;
font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 900;
text-transform: uppercase;

}

.text {
  text-align: center;
color: #212529;
font-size: 18px;
line-height: 1.5;
font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 300;
visibility: visible;
margin-top: 16px;

}
.divider {
  border-color: #47385f;
  max-width: 100px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;
}
.wrapper{
padding: 100px 0px !important;

}
@media only screen and (max-width: 480px) {
 
  .wrapper{
    padding:  0px !important;
  }
}