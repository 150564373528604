.bgImage {
	background-image: url('/src/assets/images/heroBG.jpeg') !important;
	
	background-size: cover !important;
  background-attachment: fixed !important;
  padding: 8em 0em !important;
}
.logo{
  max-width: 250px ;
  max-height: 250px;
  display: block;
  margin: auto;
}
.divider {
  border-color: #e9e6ed;
  max-width: 100px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;
  border-top: 1px solid #e9e6ed;
  border-bottom: 1px solid #e9e6ed;

}
.name{
  color: white;
  text-align: center;
  font-size: 5rem;
  line-height: 5rem;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;


}
.slogan{
  color: #fff;
  text-align: center;
  font-size: 2.2rem !important;
  line-height: 3.2rem;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
font-weight: 370;



}
@media only screen and (min-width: 1024px) {
.bgImage{
  min-height: 700px;

}

}
@media only screen and (max-width: 480px) {
  .bgImage{
    max-width: 450px;
    min-height: 50px;
    background-attachment: local !important;
    padding: 4em 0em !important;
  }
  .name{
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0;

  }
  .logo{
    max-width: 150px ;
    max-height: 150px;
  }
  .slogan{
    font-size: 1.5rem !important;
  }
  .divider{
    display: none;
  }
  
  }
  
  