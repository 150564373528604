.bgImage {
	background-image: url('/src/assets/images/footerBg.jpeg') !important;
	background-attachment: fixed !important;
	background-size: cover !important;
  text-align: center !important; 
  border-top: solid 20px #222 !important;
}

.columnStyling{
  margin: 75px 0;


}


.columnStyling h4 {
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif !important ;
  font-weight: 1000 !important;
  text-transform: uppercase !important;
  text-align: center;
} 
.columnStyling p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

}
.rowstyling{ 

  text-align: center !important;
  margin-bottom: 25px;
  padding-bottom: 70px;
}
.socials{
color: rgba(255, 255, 255, 0.7);
text-align: center !important; 
padding-top: 50px !important;
padding-bottom: 80px !important;

} 

.copyright{
  color: rgba(255, 255, 255, 0.7) ;
  text-align: center !important;
  

}
@media only screen and (max-width: 480px) {
  .bgImage{
    background-attachment: local !important;
  
  }}
