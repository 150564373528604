.divider{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-color: #47385f;
  max-width: 150px;
  height: 2px;
  border-left: 0px;
  border-right: 0px;

}
.h1Text{
  color: black;
  text-align: center!important;
  font-size: 52px;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin-top: 0;
  padding-top: 80px;
  line-height: 1.1;
}
.wrapping{
  padding-bottom: 80px !important;
}


h3{
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  font-size: 1.75rem !important;
  line-height: 1.1;
}
p{
  font-size: 18px !important;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 5rem;
  box-sizing: inherit;
  
}