.bgImage{
  background-image: url('/src/assets/images/caseStudyMorphBg.jpeg') ;
	background-attachment: fixed !important;
	background-size: cover !important;

}

.bgImageAttachment{
  background-image: url('/src/assets/images/caseStudyMorphBg.jpeg') ;
  background-size: cover !important;
}



.logoImage{
  max-height: 250px;
  padding: 20px 0px;
  max-width: 80%;
  height: auto;
  display: block!important;
  vertical-align: middle;
  border-style: none;
  margin-right: auto!important;
  margin-left: auto!important;
}
.rowstyling {
  margin-right: 0px!important;
  margin-left: 0px!important;
  justify-content: center!important;
  min-height: 320px;
  max-width: 720px; 
  box-sizing: inherit;
}

.buttonStyle{
  border: 1px solid white;
  background-color: transparent;
  font-weight: 900;
  padding: 12px 18px;
  touch-action: manipulation;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0;
  color: #fff;
  font-size: 1rem;
  line-height: 1.25;
  cursor: pointer;
  transition: .10s;
}
.buttonStyle:hover{
  background-color: white;
  color: #212529;

}

.column1{
  align-self: center!important;



}

.text{
  color: #CCCCCC;
  font-size: 18px;
  line-height: 1.5;
  margin: 0 0 1em;

}
@media only screen and (max-width: 480px) {
  .bgImage{
    background-attachment: local !important;
  
  }}